<template>
  <CContainer>
    <h1>Bienvenido a Load Crutches</h1>
    <CRow class="row-items">
      <CCol
        sm="4"
        class="componentes"
        v-for="({route, svg, title, text, goTo}, index) in appItems"
        :key="index"
      >
        <CCard class="h-100 pt-2">
          <router-link :to="route" class="svg-link">
              <span v-html="svg"></span>
          </router-link>
          <CCardBody class="pb-1" >
            <CCardTitle>{{title}}</CCardTitle>
            <CCardText >{{text}}</CCardText>
          </CCardBody>
          <CButton :to="route" color="primary">{{goTo}}</CButton>
        </CCard>

      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import "core-js/stable";
import "regenerator-runtime/runtime";
import { checkUser } from "../../utils/utils";
export default {
  name: "Inicio",
  data: () => ({
    appItems: [
      {
        route: "/user",
        svg:
          '<svg data-v-1d72844e="" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" class="bi bi-people"><path data-v-1d72844e="" d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"></path></svg>',
        title: "Usuarios",
        text:
          "Página para la administración y gestión de usuarios de la aplicación",
        goTo: "Ir a Usuarios"
      },
      {
        route: "/pathology",
        svg:
          '<svg data-v-1d72844e="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="bi bi-people"><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M88,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,128Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M256,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,128Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M424,160a64,64,0,1,0-64-64A64.072,64.072,0,0,0,424,160Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,424,64Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M88,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,288Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M424,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,288Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M88,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,448Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M256,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,448Z" class="ci-primary"></path><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M424,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,448Z" class="ci-primary"></path></svg>',
        title: "Patologías",
        text:
          "Página para la administración y gestión de las patologías de la aplicación",
        goTo: "Ir a Patologías"
      },
      {
        route: "/advice",
        svg:
          '<svg data-v-1d72844e="" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" class="bi bi-people"><path data-v-1d72844e="" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"></path></svg>',
        title: "Consejos",
        text:
          "Página para la administración y gestión de los consejos de la aplicación",
        goTo: "Ir a Consejos"
      },
      {
        route: "/challenge",
        svg:
          '<svg data-v-1d72844e="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="bi bi-people"><polygon data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" points="328.375 384 332.073 458.999 256.211 406.28 179.924 459.049 183.625 384 151.586 384 146.064 496 182.756 496 256.169 445.22 329.242 496 365.936 496 360.414 384 328.375 384" class="ci-primary"></polygon><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M415.409,154.914l-2.194-48.054L372.7,80.933,346.768,40.414l-48.055-2.2L256,16.093,213.287,38.219l-48.055,2.2L139.3,80.933,98.785,106.86l-2.194,48.054L74.464,197.628l22.127,42.715,2.2,48.053L139.3,314.323l25.928,40.52,48.055,2.195L256,379.164l42.713-22.126,48.055-2.195,25.928-40.52L413.214,288.4l2.195-48.053,22.127-42.715Zm-31.646,76.949L382,270.377l-32.475,20.78-20.78,32.475-38.515,1.76L256,343.125l-34.234-17.733-38.515-1.76-20.78-32.475L130,270.377l-1.759-38.514L110.5,197.628,128.237,163.4,130,124.88,162.471,104.1l20.78-32.474,38.515-1.76L256,52.132l34.234,17.733,38.515,1.76,20.78,32.474L382,124.88l1.759,38.515L401.5,197.628Z" class="ci-primary"></path></svg>',
        title: "Retos",
        text:
          "Página para la administración y gestión de los retos de la aplicación",
        goTo: "Ir a Retos"
      },
      {
        route: "/alert",
        svg:
          '<svg data-v-1d72844e="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="bi bi-people"><path data-v-1d72844e="" fill="var(--ci-primary-color, currentColor)" d="M450.27,348.569,406.6,267.945V184c0-83.813-68.187-152-152-152s-152,68.187-152,152v83.945L58.928,348.568A24,24,0,0,0,80.031,384h86.935c-.238,2.636-.367,5.3-.367,8a88,88,0,0,0,176,0c0-2.7-.129-5.364-.367-8h86.935a24,24,0,0,0,21.1-35.431ZM310.6,392a56,56,0,1,1-111.419-8H310.018A56.14,56.14,0,0,1,310.6,392ZM93.462,352,134.6,276.055V184a120,120,0,0,1,240,0v92.055L415.736,352Z" class="ci-primary"></path></svg>',
        title: "Alertas",
        text:
          "Página para la administración y gestión de las alertas de la aplicación",
        goTo: "Ir a Alertas"
      }
    ]
  }),
  created() {
    if (!checkUser()) return;
  },
};
</script>

<style scoped>
@import '../../assets/css/home.css';
svg {
  display: block;
  margin: 0 auto;
  padding: 10px 10px;
  transition: transform 0.2s;
  width: 100%;
  height: 180px;
}
.svg-link {
  color: inherit;
}

</style>